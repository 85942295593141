import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { AwareHttpService } from '@appbolaget/aware-http';

import { GetRoles } from './role.actions';
import { Role } from '@appbolaget/aware-model';

export interface RoleStateModel {
    roles: Role[];
}

@State({
    name: 'role',
    defaults: {
        roles: null,
    },
})
@Injectable({ providedIn: 'root' })
export class RoleState {
    constructor(private api: AwareHttpService) {}

    @Selector()
    static roles({ roles }: RoleStateModel) {
        return roles;
    }

    @Action(GetRoles)
    getRoles({ patchState }: StateContext<RoleStateModel>) {
        return this.api
            .get('roles')
            .toCollection(Role)
            .execute()
            .pipe(
                tap((result) => {
                    patchState({
                        roles: result.data,
                    });
                }),
            );
    }
}
